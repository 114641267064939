import React from 'react';
import './App.css';

import Board from './components/Board/Board';

function App() {
  
  return (
    <div>
      <Board />
    </div>
  );
}

export default App;
